import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { socket } from 'api';
import { Tooltip, Input, NumberInput } from 'libs/ui';
import { setBoardsData } from '../../../store/actions/boards';
import { BoardWrapper, Comment, Label, Places, TooltipInput, SizedBlueButton, ZoneInfoBig, Warn, TooltipPlace, Buttons } from './Board.styled';

export const Board = (props) => {
  const { data } = props;
  const { zoneId, color } = data;

  const dispatch = useDispatch();
  const zone = useSelector((state) => state.boards.boards[zoneId]);
  const newVariant = !!zone.regular;
  const cnt = zone ? zone.maximum - zone.current : 0;
  let max = zone ? zone.maximum : 0;
  // if (newVariant) {
  //   max = zone.maximum - zone.regular;
  // }

  const [comment, setComment] = useState('');
  const [newCount, setNewCount] = useState(cnt);

  const onGetZones = (msg) => {
    dispatch(setBoardsData([msg.zone]));
  };

  // get data
  useEffect(() => {
    socket.call('get_zones_status');
    window.socket.on('set_zone_status', onGetZones);

    return () => {
      window.socket.off('set_zone_status', onGetZones);
    };
  }, []);

  const updateComment = (e) => {
    setComment(e.target.value);
  };

  const accept = () => {
    socket.call(
      'set_zone_status',
      {
        user_id: window.user.getId(),
        zone_id: zoneId,
        current: max - newCount,
        comment: comment,
      },
      () => {
        setComment('');
      }
    );
  };

  const updateValue = (val) => {
    setNewCount(val);
  };

  return (
    <BoardWrapper>
      {newVariant && (
        <TooltipPlace>
          <Tooltip
            info={
              <Warn>
                Забронировано <b>{zone.regular}</b> постоянных мест из <b>{zone.maximum}</b>. Вы можете указать новое общее количество свободных мест,
                в т.ч. забронированные места. На табло отображаются свободные места для разовых клиентов
              </Warn>
            }
          />
        </TooltipPlace>
      )}
      <Places>
        <div className="left">
          <Label>Текущее</Label>
          <ZoneInfoBig zoneId={zoneId} color={color} />
        </div>
        <div className="right">
          <Label>Новое</Label>
          <NumberInput min={-9999} max={max} value={newCount} onChange={updateValue} />
        </div>
        <div className="right">
          <Label>Всего</Label>
          <Input value={max} disabled />
        </div>
      </Places>
      <Comment>
        <Label>Комментарий</Label>
        <Input value={comment} onChange={updateComment} />
      </Comment>
      <Buttons>
        <SizedBlueButton onClick={accept}>Применить</SizedBlueButton>
      </Buttons>
    </BoardWrapper>
  );
};
