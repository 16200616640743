import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Place, PlaceItem } from './options-checkbox.styled';

const OptionsCheckbox = (props) => {
  const { saveAsParam } = props;
  const [loading, setLoading] = useState(false);

  const updateState = () => {
    props.sendControlMsg('get_state');
  };

  const onGetState = () => {
    setLoading(false);
  };

  useEffect(() => {
    window.socket.on('settings.set', updateState);
    window.socket.on(props.name, updateState);
    window.socket.on('get_state', onGetState);
    return () => {
      window.socket.off('settings.set', updateState);
      window.socket.off(props.name, updateState);
      window.socket.off('get_state', onGetState);
    };
  }, []);

  const getPropName = (prop) => {
    prop = prop.split('.');
    return prop[1];
  };

  const setOption = (event) => {
    const val = event.target.checked;
    setLoading(true);
    if (!saveAsParam) {
      props.sendControlMsg(
        props.name,
        () => {
          //this.setState({ loading: false });
        },
        { enable: val }
      );
      return;
    }
    // as param
    props.sendControlMsg(
      'settings.set',
      () => {
        //this.setState({ loading: false });
      },
      { parameter: getPropName(props.name), enable: val }
    );
  };

  const name = getPropName(props.name);
  const checked = props.state[name] ?? false;
  // console.log('🚀 ~ OptionsCheckbox ~ props.state:', props.state);

  return (
    <Place loading={loading}>
      <PlaceItem>
        <input type="checkbox" id={props.name} checked={checked} onChange={setOption} />
        <label htmlFor={props.name}>{props.title}</label>
      </PlaceItem>
    </Place>
  );
};

OptionsCheckbox.propTypes = {
  state: PropTypes.object,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  saveAsParam: PropTypes.bool,
  sendControlMsg: PropTypes.func.isRequired,
};

export default OptionsCheckbox;
