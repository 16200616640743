import React from 'react';
//import { connect } from 'react-redux';

import { Confirm } from 'libs/ui';
import withRackLogic from '../racksControl/withRackLogic';
import { GrayButton } from '../../../shared/buttons';
import { Items, Wrapper, Left, Right, Limiter, LimiterItem, ButtonPlace } from './options.styled';
import Reload from './reload';
import { OptionsCam } from './options.cam';
import { OptionsCamPoly } from './options.cam-poly';
import OptionsCheckbox from './options-checkbox';

// const polygonalCam = true;

class Options extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      confirmReconc: false,
    };
  }

  componentDidMount = () => {
    this._ismounted = true;

    // limit
    window.socket.on('settings.set_vehicle_plate_check', this.updateSocketState);
    window.socket.on('get_state', this.setLoadingOff);

    this.updateSocketState();
  };

  componentWillUnmount = () => {
    this._ismounted = false;
    window.socket.off('settings.set_vehicle_plate_check', this.updateSocketState);
    window.socket.off('get_state', this.setLoadingOff);
  };

  setLoadingOff = () => {
    this.setState({ loading: false });
  };

  updateSocketState = () => {
    this.props.sendControlMsg('get_state');
  };

  selectAll = (event) => {
    event.target.select();
  };

  setLimitEnabled = (event) => {
    const val = event.target.checked;
    this.setState({ loading: true });
    this.props.sendControlMsg(
      'settings.set_vehicle_plate_check',
      () => {
        //this.setState({ loading: false });
      },
      { enable: val, distance: this.props.status.check_vehicle_plate_distance }
    );
  };

  setLimitValue = (event) => {
    let val = parseInt(event.target.value, 0);
    if (val < 0) {
      val = 0;
    }
    if (val > 9) {
      val = 9;
    }
    //console.log('🚀 ~ val', val);
    if (isNaN(val)) {
      return;
    }
    this.setState({ loading: true });
    this.props.sendControlMsg(
      'settings.set_vehicle_plate_check',
      () => {
        //this.setState({ loading: false });
      },
      { enable: this.props.status.check_vehicle_plate_enable, distance: val }
    );
  };

  getNode = () => {
    return window.service.call('getNode', this.props.id);
  };

  getItems = () => {
    const out = [];
    const node = this.getNode();
    if (!node) {
      return out;
    }
    const { rack_type } = node;
    const { status, sendControlMsg } = this.props;

    // all
    out.push(
      <OptionsCheckbox
        key={'settings.1'}
        sendControlMsg={sendControlMsg}
        title="Проезд по РФИД без поиска парковки"
        name="settings.force_passage_by_rfid"
        state={status}
      />
    );
    out.push(
      <OptionsCheckbox
        key={'settings.2'}
        sendControlMsg={sendControlMsg}
        title="Проезд по БСК без поиска парковки"
        name="settings.force_passage_by_scard"
        state={status}
      />
    );
    out.push(
      <OptionsCheckbox
        key={'settings.3'}
        sendControlMsg={sendControlMsg}
        title="Проезд по банковским картам"
        name="settings.allow_passage_by_bcard"
        state={status}
      />
    );

    // leave
    if (rack_type === 'leave' || rack_type === 'zone') {
      out.push(
        <OptionsCheckbox
          key={'settings.4'}
          sendControlMsg={sendControlMsg}
          title="Чтение QR с чека оплаты на стойке"
          name="settings.allow_passage_by_qrcode"
          state={status}
        />
      );
    }

    // zone or leave
    if (rack_type === 'zone' || rack_type === 'leave') {
      out.push(
        <OptionsCheckbox
          key={'settings.5'}
          sendControlMsg={sendControlMsg}
          title="Автоматическое разрешение проезда по ГРЗ"
          name="settings.auto_allow_travel"
          state={status}
        />
      );
    }

    // zone or entry
    if (rack_type === 'zone' || rack_type === 'entry') {
      out.push(
        <OptionsCheckbox
          key={'settings.6'}
          sendControlMsg={sendControlMsg}
          title="Блокировать при отсутствии мест в зоне"
          name="settings.close_by_no_free_places"
          state={status}
        />
      );
    }

    //  entry
    if (rack_type === 'entry') {
      out.push(
        <OptionsCheckbox
          key={'settings.7'}
          sendControlMsg={sendControlMsg}
          title="Въезд без билета"
          name="settings.allow_passage_by_plate"
          saveAsParam={true}
          state={status}
        />
      );
    }
    return out;
  };

  showConfirmReconc = () => {
    this.setState({ confirmReconc: true });
  };

  closeConfirm = () => {
    this.setState({ confirmReconc: false });
  };

  reconciliation = () => {
    this.props.sendControlMsg('reconciliation', () => {
      this.closeConfirm();
    });
  };

  getAdditionalCmp = () => {
    const node = this.getNode();
    return node?.rack_type === 'zone' || node?.rack_type === 'leave' ? (
      <ButtonPlace>
        <GrayButton disabled={this.state.confirmReconc} className="btn" onClick={this.showConfirmReconc}>
          Сверка итогов
        </GrayButton>
        {this.state.confirmReconc && (
          <Confirm
            close={this.closeConfirm}
            action={this.reconciliation}
            title={'Провести сверку итогов?'}
            actionTitle={<>&nbsp; &nbsp; Ок &nbsp; &nbsp;</>}
          />
        )}
      </ButtonPlace>
    ) : null;
  };

  render() {
    const { loading } = this.state;
    const { id, status, sendControlMsg } = this.props;
    const { mode } = status;
    const offline = mode === 'offline';
    let cl2 = offline ? 'offline1' : !mode ? 'loading1' : '';

    const withLimit = status.check_vehicle_plate_enable !== undefined;
    const limitEnabled = status.check_vehicle_plate_enable;
    const limitValue = status.check_vehicle_plate_distance;

    const items = this.getItems();
    const add = this.getAdditionalCmp();
    const polygonalCam = status?.recognition?.polygon !== undefined;

    return (
      <Wrapper>
        <Left>
          {polygonalCam ? (
            <OptionsCamPoly sendControlMsg={sendControlMsg} id={id} add={add} />
          ) : (
            <OptionsCam status={status} sendControlMsg={sendControlMsg} id={id} add={add} />
          )}
        </Left>
        <Right className={cl2}>
          {withLimit && (
            <Limiter loading={loading}>
              <LimiterItem>
                <input type="checkbox" id="limitEnabled" checked={limitEnabled} onChange={this.setLimitEnabled} />
                <label htmlFor="limitEnabled">Контроль ГРЗ</label>
              </LimiterItem>
              {limitEnabled && (
                <LimiterItem>
                  <label className="left-side" htmlFor="limitValue">
                    Расхождение, символов
                  </label>
                  <input onFocus={this.selectAll} min={0} max={9} type="number" id="limitValue" value={limitValue} onChange={this.setLimitValue} />
                </LimiterItem>
              )}
            </Limiter>
          )}

          {items.length && <Items>{items}</Items>}

          <Reload id={id} />
        </Right>
      </Wrapper>
    );
  }
}

//export default  Options;

const rackSettings = {
  type: 'rack',
  reloadType: 'reboot controller',
};
export default withRackLogic(Options, rackSettings);
