import React from 'react';

import { user } from 'services/user';
import { UserWrapper, Button, Subbutton } from './User.styled';

const User = (props) => {
  let name = '';
  if (window.user.info && window.user.info.config) {
    name = window.user.info.config.name;
  }

  /*
  const showPopup = () => {
    props.openWindow({
      id: 'settingswindow',
      type: 'settings',
      data: {},
      header: 'Настройка вида парковочного билета',
    });
  };
  */

  const showSIPPopup = () => {
    props.openWindow({
      id: 'settingssipwindow',
      type: 'settingssip',
      data: {},
      header: 'Настройка SIP номера',
    });
  };

  const showLimitsPopup = () => {
    props.openWindow({
      id: 'settingslimitswindow',
      type: 'settingslimits',
      data: {},
      header: 'Бронирование парковочных мест',
    });
  };

  const withRegular = user.haveRight('zones.set_regular_places');

  return (
    <UserWrapper>
      <Button>
        Настройки
        <span className="menu1">
          <span className="menu1items">
            {/*
            <Subbutton className="sb-top" onClick={showPopup}>
              Вид парковочного билета
            </Subbutton>*/}
            <Subbutton className="sb-all" onClick={showSIPPopup}>
              SIP номер
            </Subbutton>
            {withRegular && (
              <Subbutton className="sb-all" onClick={showLimitsPopup}>
                Бронирование мест
              </Subbutton>
            )}
          </span>
        </span>
      </Button>
      <div>{name}</div>
      <img onClick={window.user.logoutUser} src="/images/button-logout.png" alt="" />
    </UserWrapper>
  );
};

export default User;
