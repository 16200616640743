import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactLassoSelect from 'libs/lasso';

import { Content, Place, RectorPlace, Buttons, Info } from './options.cam-poly.styled';
import services from '../../../../services';
import { VideoBlockCap } from '../racksControl/styledComponents';
import VideoBlockUrls from '../racksControl/VideoBlockUrls';
import { GrayButton } from '../../../shared/buttons';

// const test = false;
const maxW = 500;
const maxH = 310;
// const kw = 430 / maxW;
// const kh = 280 / maxH;
let createTime;

const w = maxW;
const h = maxH;

// const toPercentX = (val, tofixed = false) => {
//   let v = (100 * val) / maxW / kw;
//   if (tofixed) {
//     v = v.toFixed(2);
//   }
//   return v;
// };
// const toPercentY = (val, tofixed = false) => {
//   let v = (100 * val) / maxH / kh;
//   if (tofixed) {
//     v = v.toFixed(2);
//   }
//   return v;
// };

const fromPercent = (points) => {
  return points.map((point) => {
    const x = parseFloat(((point[0] * w) / 100).toFixed(2));
    const y = parseFloat(((point[1] * h) / 100).toFixed(2));
    return { x, y };
  });
};

const toPercent = (points) => {
  return points.map((point) => {
    const x = parseFloat(((point.x * 100) / w).toFixed(2));
    const y = parseFloat(((point.y * 100) / h).toFixed(2));
    return { x, y };
  });
};

/**
 * component
 * @param {*} props
 * @returns
 */
export const OptionsCamPoly = (props) => {
  const { sendControlMsg, id, add } = props;
  const [selection, setSelection] = useState(false);
  const [cams, setCams] = useState(null);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({});
  const [points, setPoints] = useState(
    fromPercent([
      [0, 0],
      [100, 0],
      [100, 100],
      [0, 100],
    ])
  );
  const { mode } = status;

  // size
  // const size = useWindowSize();
  // const scale = k;//data.getScale();

  const onGetSocketState = (msg) => {
    setLoading(false);
    setStatus(msg);
    if (msg.recognition && msg.recognition.polygon) {
      setPoints(fromPercent(msg.recognition.polygon));
    }
  };

  const getVideoUrls = (msg) => {
    if (msg.target === id) {
      setCams(services.prepareVideoUrls(msg));
    }
  };

  const setLoadingOff = () => {
    setLoading(false);
  };

  /**
   * on mount
   */
  useEffect(() => {
    createTime = moment().unix();
    sendControlMsg('get_video_urls');
    window.socket.on('get_video_urls', getVideoUrls);
    window.socket.on('settings.set', setLoadingOff);
    window.socket.on('get_state', onGetSocketState);
    sendControlMsg('get_state');

    return () => {
      window.socket.off('get_video_urls', getVideoUrls);
      window.socket.off('settings.set', setLoadingOff);
      window.socket.off('get_state', onGetSocketState);
    };
  }, []);

  /**
   * start selection
   */
  const startSelection = () => {
    setSelection(true);
  };

  /**
   * save selection
   */
  const saveSelection = () => {
    // console.log('save', points);
    if (points.length < 4) {
      return;
    }

    const coords = toPercent(points).map((el) => [el.x, el.y]);

    setLoading(true);
    sendControlMsg(
      'settings.set',
      () => {
        setSelection(false);
      },
      { parameter: 'recognition_polygon', coords }
    );
  };

  /**
   * get selection string
   * @returns
   */
  const getSelectionStr = () => {
    const out = toPercent(points);
    return out.map(({ x, y }) => `[${x}%, ${y}%]`).join(', ');
  };

  // const getImageSrc = () => {
  //   if (!cams || !cams.length) {
  //     return '';
  //   }
  //   const src = cams[0].url;
  //   return test ? '/images/test/' + id + '.jpg?=ct=' + createTime : src;
  // };

  // render

  let h1 = maxH + 'px';
  const offline = mode === 'offline';
  let cl2 = offline ? 'offline1' : !mode ? 'loading1' : '';
  if (loading) {
    cl2 += ' loading1';
  }
  // const src = getImageSrc();
  const valid = selection && points.length > 3;

  // if (props.loading) {
  //   return null;
  // }

  return (
    <Content>
      <Place>
        {cams === null || !cams.length ? (
          <VideoBlockCap
            className={cl2}
            style={{
              height: h1,
              width: maxW + 'px',
            }}
          />
        ) : (
          <VideoBlockUrls key="vblock1" createtime={createTime} rackId={id} camHeight={maxH + 'px'} cams={[cams[0]]} />
        )}
        <RectorPlace $active={selection}>
          <ReactLassoSelect
            disabled={!selection}
            value={points}
            onChange={(value) => {
              setPoints(value);
            }}
            style={{
              width: `${maxW}px`,
              height: `${maxH}px`,
            }}
            // style={{
            //   transformOrigin: '0 0',
            //   transform: `scale(${scale})`,
            // }}
            // imageStyle={{ width: `${maxW}px`, height: `${maxH}px` }}
            // viewBox={{
            //   left: 0,
            //   top: 0,
            //   width: maxW,
            //   height: maxH,
            // }}
            // onComplete={(value) => {
            //   if (!value.length) return;
            //   getCanvas(src, value, (err, canvas) => {
            //     if (!err) {
            //       setClippedImg(canvas.toDataURL());
            //     }
            //   });
            // }}
          />
        </RectorPlace>
      </Place>
      <Info>{getSelectionStr()}</Info>
      <Buttons className={cl2}>
        <GrayButton className="btn" onClick={startSelection} disabled={selection}>
          Выделить область ГРЗ
        </GrayButton>
        <GrayButton className="btn" onClick={saveSelection} disabled={!valid}>
          Сохранить область ГРЗ
        </GrayButton>
        {add}
      </Buttons>
    </Content>
  );
};

OptionsCamPoly.propTypes = {
  loadingRecogn: PropTypes.bool,
  id: PropTypes.string.isRequired,
  // status: PropTypes.object,
  sendControlMsg: PropTypes.func.isRequired,
  add: PropTypes.any,
};
