import { useDispatch } from 'react-redux';

import { openWindow } from '../../store/actions/windows';
import { ZoneInfo } from '../shared/zone/ZoneInfo';
import VideoBlock from '../VideoBlock';
import MapLocation from './LocationsMap';
import RacksMap from './RacksMap';
import { MapWrapper } from './ZonesMap.styled';

export const ZonesMap = () => {
  const dispatch = useDispatch();

  const getBoards = () => {
    const boards = window.config.objects ? window.config.objects.boards : [];
    if (!boards) {
      return null;
    }

    return boards.map((el, idx) => {
      return (
        <ZoneInfo
          key={idx}
          top={el.top}
          left={el.left}
          zoneId={el.zoneId}
          color={el.color}
          number={0}
          onClick={() =>
            dispatch(
              openWindow({
                id: '0',
                type: 'single_board',
                data: {
                  zoneId: el.zoneId,
                  color: el.color,
                },
              })
            )
          }
        />
      );
    });
  };

  const nodes = window.config.nodes ? window.config.nodes : [];
  const cameraID = 'payment.01';
  if (!nodes) {
    return null;
  }
  const node = nodes[cameraID];
  const boards = getBoards();

  return (
    <MapWrapper>
      <img src="/images/background2.png" alt="" width={1876} height={970} />
      <VideoBlock cameraID={cameraID} node={node} />
      <MapLocation />
      <RacksMap />
      {boards}
    </MapWrapper>
  );
};
