import { useSelector } from 'react-redux';

import { Content, ZoneIcon, Number } from './ZoneInfo.styled';

export const ZoneInfo = (props) => {
  const { zoneId, onClick, left, top, color } = props;
  const rightSetStatus = window.haveRight('mnemo.set_zone_status');
  const count = useSelector((store) => (store.boards.boards[zoneId] ? store.boards.boards[zoneId].maximum - store.boards.boards[zoneId].current : 0));

  return (
    <Content disabled={!rightSetStatus} className={props.className} onClick={rightSetStatus ? onClick : null} left={left} top={top}>
      <ZoneIcon color={color}>{zoneId}</ZoneIcon>
      <Number>{count}</Number>
    </Content>
  );
};
