import styled from 'styled-components';

export const Content = styled.div`
  padding: 15px;
  box-sizing: border-box;
  input {
    font-size: 14px;
    font-family: monospace, monospace;
    overflow: hidden;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 3px;
    width: 100%;
  }
`;

export const Buttons = styled.div`
  justify-content: space-between;
  display: flex;
`;

export const Button = styled.button`
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  color: #eee;
  border-radius: 3px;
  padding: 0 15px;
  border: 0;
  cursor: pointer;
  background: #48515b;
  margin: 0;
  position: relative;
  transition: 0.3s all ease-in;
  &:hover {
    background: #28313b;
  }
`;

export const Item = styled.div`
  margin: 0 0 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Title = styled.div`
  width: 100px;
  color: #fff;
  padding-right: 20px;
`;

export const Value = styled.div`
  width: 80px;
  margin-right: 20px;
`;

export const ItemButtons = styled.div``;
