import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Window from 'components/shared/window/window';
import { closeWindow } from '../../../store/actions/windows';
import { Content, Button, Buttons } from './foto.styled';

export const FotoWindow = (props) => {
  const { data } = props;
  const [title, setTitle] = useState('Загрузка...');
  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeWindow('foto'));
  };

  const onLoad = () => {
    setTitle('ok');
  };

  const onError = () => {
    setTitle('Ошибка загрузки');
  };

  const url = data.data;
  if (!url) {
    close();
    return null;
  }

  const w = 774;
  const h = 480;
  const x1 = Math.round((window.innerWidth - w) / 2);
  const y1 = Math.round((window.innerHeight - h) / 2);

  const other = {
    defaultPosition: { x: x1, y: y1 - 80 },
  };

  return (
    <Window width={w + 'px'} height={h + 'px'} contentHeight={h - 12} {...other} {...props.data}>
      <Content>
        <img src={url} alt={title} onLoad={onLoad} onError={onError} />
        <Buttons>
          <Button onClick={close}>Закрыть</Button>
        </Buttons>
      </Content>
    </Window>
  );
};
