import styled from 'styled-components';

import { ZoneInfo } from 'components/shared/zone/ZoneInfo';
import { BlueButton } from 'components/shared/buttons';

export const BoardWrapper = styled.div`
  height: 100%;
  padding: 30px 30px;
`;


export const ZoneInfoBig = styled(ZoneInfo)`
  /* transform: scale(1.9); */
  cursor: default;
  margin: 0;
  position: static;
  left: auto;
  top: auto;
  height: 48px;
  width: 125px;
`;

export const Places = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 15px;
  & > div {
    width: 33%;
  }
`;


export const Label = styled.div`
  font-size: 13px;
  color: #ffffff;
  opacity: 0.6;
  margin-bottom: 5px;
`;

export const Comment = styled.div`
  margin: 5px 0;
`;

export const Warn = styled.div`
  color: #fff;
  text-align: justify;
  font-size: 14px;
  line-height: 1.5;
  b {
    color: #aaa;
    font-weight: normal;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 25px 0 0;
`;

export const SizedBlueButton = styled(BlueButton)`
  width: 150px;
  height: 35px;
`;

export const TooltipPlace = styled.div`
  /* margin-left: 15px; */
  position: absolute;
  right: 43px;
  top: 5px;
  z-index: 9999;
`;

export const TooltipInput = styled.div`
  display: flex;
  align-items: center;
  input {
    width: 110px;
  }
`;
