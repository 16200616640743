import styled from 'styled-components';

export const Content = styled.input`
  border-radius: 4px;
  border: solid 1px #a3b0c0;
  background-color: #393e44;
  width: 100%;
  height: 48px;
  font-size: 20px;
  color: #ffffff;
  outline: none;
  padding: 0 15px;
  box-sizing: border-box;
  text-align: left;
  &[disabled] {
    color: #aaa;
    /* border-color: #aaa; */
  }
`;
