import { useWindowSize } from 'libs/hooks';
import { getScale } from 'libs/common';
import { data } from 'services/data';
import { Unrecognized } from 'libs/ui';
import Windows from './components/WindowsController';
import Widgets from './components/WidgetsController';
import Sounds from './components/SoundsController';
import TopBar from './components/TopBar';
import { ZonesMap } from './components/maps/ZonesMap';
import { Content, ContentOther, Position, Position2 } from './app-main.styled';

export const AppMain = (props) => {
  const { unrecognized, dropCall } = props;
  const withUnrecognized = unrecognized?.source !== undefined;

  // size
  const size = useWindowSize();
  /**
   * check width
   */
  if (!size.width || !size.height) {
    return null;
  }

  // scale
  const scale = getScale(size);
  const scaleOther = getScale(size, true);
  data.setScale(scaleOther);

  return (
    <>
      <TopBar />
      <Position>
        <Content scale={scale}>
          <ZonesMap />
          <Sounds />
          {withUnrecognized && <Unrecognized dropCall={dropCall} data={unrecognized} id={unrecognized.source} />}
          <Widgets />
        </Content>
        <Position2>
          <ContentOther scale={scaleOther}>
            <Windows />
          </ContentOther>
        </Position2>
      </Position>
    </>
  );
};

export default AppMain;
