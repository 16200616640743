import { Content, Arrows, StyledInput } from './number-input.styled';

export const NumberInput = (props) => {
  const def = {
    min: 0,
  };
  const propsOk = { ...def, ...props };
  const { value, onChange, min, max } = propsOk;

  /**
   * change from input
   * @param {*} event
   */
  const change = (event) => {
    const val = parseInt(event.target.value, 0);
    if (val <= max && val >= min) {
      onChange(val);
    }
  };

  /**
   * inc
   */
  const increaseValue = () => {
    if (value < max) {
      onChange(value + 1);
    }
  };

  /**
   * dec
   */
  const decreaseValue = () => {
    if (value > min) {
      onChange(value - 1);
    }
  };

  return (
    <Content>
      <StyledInput type="number" min={min} max={max} value={value} onChange={change} />
      <Arrows>
        <img src="/images/arrow-up.png" onClick={increaseValue} alt="up" />
        <img src="/images/arrow-down.png" onClick={decreaseValue} alt="down" />
      </Arrows>
    </Content>
  );
};
