import { Content } from './input.styled';

export const Input = (props) => {
  const def = {
    type: 'text',
  };
  const propsOk = { ...def, ...props };
  const { value, onChange, ...rest } = propsOk;

  return <Content value={value} onChange={onChange} {...rest} />;
};
