import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { socket } from 'api';
import { notify } from 'libs/common';
import Window from 'components/shared/window/window';
import { closeWindow } from 'store/actions/windows';
import { Button, Buttons, Content, Item, Title, Value, ItemButtons } from './index.styled';

export const SettingsLimits = (props) => {
  const { data } = props;
  const dispatch = useDispatch();
  const zones = useSelector((state) => state.boards.boards);
  // console.log('🚀 ~ SettingsLimits ~ zones:', zones);
  const [zonescnt, setZonescnt] = useState(zones);

  const changeZone = (event) => {
    let val = event.target.value;
    if (!val) {
      val = 0;
    }
    const name = event.target.name;
    const out = { ...zonescnt };
    out[name].regular = val;
    setZonescnt(out);
  };

  const close = () => {
    dispatch(closeWindow('settingslimitswindow'));
  };

  const save = (zoneId) => {
    const out = { zone_id: zoneId, count: parseInt(zonescnt[zoneId].regular) };

    // console.log('🚀 ~ save ~ out:', out);
    socket.call('set_zone_regular_places', out);
    notify('Бронирование парковочных мест', 'Изменения для зоны ' + zoneId + ' сохранены', 'success');
    // close();
  };

  const getItemsEl = () => {
    return Object.keys(zones).map((id) => {
      const zone = zones[id];
      const val = zonescnt[id].regular;
      if (val === undefined) {
        return (
          <Item>
            <Title>{zone.name}: не поддерживается </Title>
          </Item>
        );
      }
      return (
        <Item>
          <Title>{zone.name}</Title>
          <Value>
            <input type="number" name={id} value={val} onChange={changeZone} max={999} min={0} />
          </Value>
          <ItemButtons>
            <Button onClick={() => save(id)}>Сохранить</Button>
          </ItemButtons>
        </Item>
      );
    });
  };

  if (!zones || !Object.keys(zones).length) {
    return null;
  }

  const itemsEl = getItemsEl();

  const x = window.innerWidth / 2 - 162;

  return (
    <Window width="350px" {...data} defaultPosition={{ x, y: 40 }}>
      <Content>
        {itemsEl}

        <Buttons>
          <Button onClick={close}>Отменить</Button>
        </Buttons>
      </Content>
    </Window>
  );
};
