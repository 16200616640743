import styled from 'styled-components';

import { Input } from '../input/input';

export const Content = styled.div`
  position: relative;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  display: flex;
  align-items: center;
`;

export const StyledInput = styled(Input)`
  padding: 0 40px 0 15px;
  text-align: right;
`;

export const Arrows = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0;
  box-sizing: border-box;
  user-select: none;
  img {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
`;
