import styled from 'styled-components';

export const Content = styled.div`
  box-sizing: border-box;
  position: absolute;
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  width: 115px;
  height: 58px;
  border-radius: 4px;
  background-color: #13161a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;

export const ZoneIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  font-size: 14px;
  color: #babcbe;
`;

export const Number = styled.div`
  font-size: 22px;
  color: #818488;
  font-family: 'Conv_LCDNOVA';
  letter-spacing: 5px;
`;
