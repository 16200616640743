import styled from 'styled-components';

export const Close = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  height: 38px;
  width: 38px;
  cursor: pointer;
  transition: 0.15s all ease-in;
  border: 0;  
  background-color: #090909;
  img {
    height: 15px;
  }
  &:hover {
    background-color: #aaa;
  }
`;

export const Header = styled.div`
  background-color: #1c2024;
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  box-sizing: border-box;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  color: #fff;
  position: relative;
  height: 38px;
  width: 100%;
  flex-shrink: 0;
  padding-right: 30px;
  p {
    margin: 0;
  }
`;

export const TabsBox = styled.div`
  width: 100%;
  height: 45px;
  padding: 0 15px;
  box-sizing: border-box;
  background-color: #262b31;
  display: flex;
`;

export const Content = styled.div`
  background-color: #393e44;
  flex: 1;
  /* max-height: ${(props) => props.contentHeight}; */
  /* overflow-x: hidden;
  overflow-y: auto; */
`;

export const Tab = styled.p`
  cursor: pointer;
  margin: 15px 40px 0 0;
  padding-bottom: 6px;
  font-size: 16px;
  color: #3ea5ff;
  border-bottom: ${(props) => (props.active ? 'solid 4px #3ea5ff' : 'none')};
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
`;
